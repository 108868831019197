import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'motion/react';

import './index.css';

import {
  CustomTextField,
  CustomToggleButtonGroup,
  CustomToggleButton
} from '../../styles/styled-components';

function PlayersNumber({ bordercolor, onChangeNumber, value }) {
  const [visible, setVisible] = useState(false);

  const handleNumberChange = (_, value) => {
    onChangeNumber(value);
  };

  const handleTextFieldClick = () => {
    setVisible(true);
    onChangeNumber(value);
  };

  return visible ? (
    <motion.div key="btns" initial={{ opacity: 0, scale: 0 }} animate={{ opacity: 1, scale: 1 }}>
      <CustomToggleButtonGroup
        exclusive
        value={value}
        onChange={handleNumberChange}
        aria-label="Number of players">
        {[...Array(10)].map((_, index) => (
          <div className="player-number__wrap" key={index}>
            <CustomToggleButton value={index + 1} bordercolor={bordercolor}>
              {index + 1}
            </CustomToggleButton>
          </div>
        ))}
      </CustomToggleButtonGroup>
    </motion.div>
  ) : (
    <motion.div key="field" initial={{ opacity: 1 }} exit={{ opacity: 0, scale: 0 }}>
      <CustomTextField
        onClick={handleTextFieldClick}
        label="Number of players"
        name="playersNumber"
        variant="outlined"
        placeholder="Enter number of players"
        bordercolor={bordercolor}
        fullWidth
      />
    </motion.div>
  );
}

PlayersNumber.propTypes = {
  bordercolor: PropTypes.string,
  onChangeNumber: PropTypes.func.isRequired,
  value: PropTypes.number
};

export default PlayersNumber;

import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

function BadgeLogo({ color }) {
  return (
    <div className="badge-logo__wrap">
      {/* S */}
      <svg
        width="23"
        height="28"
        viewBox="0 0 23 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.34945 13.9775C9.34945 13.2392 9.33672 12.6791 9.31126 12.2972L9.27307 10.1203C9.27307 7.11608 9.6677 4.68466 10.457 2.82609C11.2208 0.942059 12.7611 5.14984e-05 15.0779 5.14984e-05C18.0822 5.14984e-05 20.0935 1.40035 21.1119 4.20094C22.1048 6.97606 22.6013 10.1585 22.6013 13.7484C22.6013 17.3128 22.0794 20.5843 21.0355 23.5632C19.9662 26.5165 17.9294 27.9932 14.9252 27.9932V19.1331C16.1218 19.1331 16.9238 18.6112 17.3311 17.5674C17.713 16.5235 17.904 15.2505 17.904 13.7484C17.904 12.0426 17.7003 10.7823 17.2929 9.96759C16.8601 9.12742 16.1727 8.70732 15.2307 8.70732C14.3905 8.70732 13.8686 9.19106 13.6649 10.1585C13.4358 11.126 13.3212 12.3481 13.3212 13.8248V15.1614C13.3212 17.4782 13.2066 19.5405 12.9775 21.3482C12.7229 23.1303 12.1373 24.6834 11.2208 26.0073C10.3042 27.3312 8.92937 27.9932 7.09626 27.9932C4.19383 27.9932 2.29707 26.5674 1.40598 23.7159C0.514883 20.839 0.0693359 17.5292 0.0693359 13.7866C0.0693359 10.2476 0.591262 7.06517 1.63512 4.23913C2.67897 1.41308 4.70303 5.14984e-05 7.70729 5.14984e-05L7.6691 8.7837C6.37065 8.7837 5.56866 9.44566 5.26315 10.7696C4.95763 12.0935 4.80487 13.1373 4.80487 13.9011C4.80487 15.0723 4.9449 16.2434 5.22496 17.4146C5.47955 18.5603 6.06513 19.1331 6.98169 19.1331C8.50928 19.1331 9.29853 17.4146 9.34945 13.9775Z"
          fill={color}
        />
      </svg>

      {/* U */}
      <svg
        width="23"
        height="29"
        viewBox="0 0 23 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.910156 9.4756L0.910156 0.691931H21.7236V9.4756H19.1267C21.3162 11.4105 22.411 14.3511 22.411 18.2974C22.411 21.429 21.4053 23.8604 19.394 25.5917C17.3827 27.2975 14.0856 28.1504 9.50286 28.1504H0.910156L0.910156 19.3667H9.38829C10.9923 19.3667 12.3034 19.2776 13.3218 19.0994C14.3402 18.9212 15.1931 18.4884 15.8806 17.801C16.568 17.1135 16.9117 16.0442 16.9117 14.593C16.9117 12.5817 16.2243 11.2323 14.8494 10.5449C13.4491 9.83203 11.6288 9.4756 9.38829 9.4756H0.910156Z"
          fill={color}
        />
      </svg>

      {/* P */}
      <svg
        width="29"
        height="30"
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.7935 0.788509C13.6011 0.83943 15.3197 1.2977 16.9491 2.16334C18.5785 3.00352 19.9025 4.18741 20.9209 5.715C21.9138 7.24259 22.4103 8.97385 22.4103 10.9088C22.4103 15.3134 21.4173 18.5977 19.4314 20.7618H28.9789V29.5454H0.909404L0.909404 21.4874L3.58269 20.9909C1.39314 18.8523 0.349286 15.4916 0.451125 10.9088C0.451125 8.94839 0.934863 7.21713 1.90234 5.715C2.86981 4.18741 4.19372 2.99079 5.87408 2.12515C7.55443 1.25952 9.43846 0.813969 11.5262 0.788509H11.7935ZM17.2164 15.3006C17.2164 13.5184 16.6691 12.1436 15.5743 11.1761C14.454 10.1832 13.1301 9.66127 11.6025 9.61035C9.84582 9.66127 8.40733 10.1705 7.2871 11.1379C6.1414 12.0799 5.56856 13.4675 5.56856 15.3006C5.56856 16.3445 5.83589 17.2865 6.37054 18.1267C6.87974 18.9414 7.57989 19.5906 8.47098 20.0743C9.33662 20.5326 10.2659 20.7618 11.2588 20.7618H11.3352C13.1174 20.7618 14.5432 20.2653 15.6125 19.2723C16.6818 18.254 17.2164 16.9301 17.2164 15.3006Z"
          fill={color}
        />
      </svg>
      {/* M */}
      <svg
        width="22"
        height="45"
        viewBox="0 0 22 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.223633 9.20747C0.223633 7.14522 0.64372 5.49034 1.4839 4.24281C2.32407 2.96982 3.68617 2.02779 5.5702 1.41675C7.42877 0.805717 9.9493 0.500191 13.1318 0.500191H21.7245V9.28384H13.2463C11.7188 9.28384 10.4203 9.38568 9.35099 9.58936C8.28168 9.76758 7.40331 10.1877 6.7159 10.8496C6.02848 11.4861 5.68477 12.4409 5.68477 13.7139C5.68477 14.9614 6.02848 15.9034 6.7159 16.5399C7.37785 17.1509 8.23076 17.5583 9.27461 17.762C10.293 17.9402 11.5787 18.0293 13.1318 18.0293H21.7245V26.813H13.2463C11.6933 26.813 10.3948 26.9021 9.35099 27.0803C8.28168 27.2585 7.40331 27.6659 6.7159 28.3024C6.02848 28.9389 5.68477 29.8936 5.68477 31.1666C5.68477 32.516 6.02848 33.5089 6.7159 34.1454C7.40331 34.7819 8.29441 35.1893 9.38918 35.3675C10.4585 35.5202 11.897 35.5966 13.7046 35.5966H21.7245V44.4185H0.911049L0.911049 36.5514L3.39339 35.9021C2.29861 34.9347 1.49663 33.6998 0.987427 32.1977C0.478231 30.6701 0.223633 28.837 0.223633 26.6984C0.223633 24.8398 0.567341 23.3122 1.25476 22.1156C1.94217 20.919 3.06241 19.9897 4.61546 19.3278C3.06241 18.4621 1.94217 17.2146 1.25476 15.5852C0.567341 13.9557 0.223633 11.8298 0.223633 9.20747Z"
          fill={color}
        />
      </svg>

      {/* A */}
      <svg
        width="23"
        height="29"
        viewBox="0 0 23 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M-0.00585937 14.5775C-0.00585937 10.9367 0.605177 7.66512 1.82725 4.7627C3.04932 1.86028 5.20068 0.409054 8.28132 0.409054H21.7241V8.73443L19.0127 9.38366H18.8217C19.9674 10.0202 20.8076 11.3695 21.3422 13.4318C21.8769 15.4686 22.1442 17.6963 22.1442 20.115C22.1442 22.0499 21.7241 23.9849 20.884 25.9198C20.0183 27.8548 18.2234 28.8223 15.4992 28.8223C11.1965 28.8223 9.04512 26.0089 9.04512 20.3823C9.04512 16.767 9.01966 14.2465 8.96874 12.8207C8.89236 11.395 8.76506 10.453 8.58684 9.9947C8.38316 9.53642 8.02672 9.29455 7.51753 9.26909H7.02106C6.18088 9.29455 5.56985 9.85467 5.18795 10.9494C4.78059 12.0188 4.57691 13.2408 4.57691 14.6157C4.60237 16.0414 4.8697 17.2635 5.3789 18.2819C5.8881 19.2748 6.66462 19.7713 7.70848 19.7713L7.70848 28.5931C5.77353 28.5931 4.23321 27.8293 3.08751 26.3017C1.91636 24.7741 1.11437 22.9538 0.681557 20.8406C0.223279 18.702 -0.00585937 16.6143 -0.00585937 14.5775ZM16.5685 15.1503C16.5685 13.2663 16.1484 11.8405 15.3082 10.8731C14.4681 9.90558 13.3988 9.38367 12.1003 9.30729H11.5656C11.973 10.2748 12.1767 11.8151 12.1767 13.9282C12.1767 16.1178 12.3294 17.6581 12.635 18.5492C12.9405 19.4148 13.5006 19.8604 14.3153 19.8858C15.0791 19.8095 15.652 19.4148 16.0338 18.702C16.3903 17.9636 16.5685 16.7797 16.5685 15.1503Z"
          fill={color}
        />
      </svg>

      {/* C */}
      <svg
        width="23"
        height="30"
        viewBox="0 0 23 30"
        fill="none"
        className="hidden"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.4506 29.1782C9.31193 29.1782 7.38971 28.5417 5.6839 27.2687C3.95263 25.9957 2.60326 24.2517 1.63578 22.0367C0.668307 19.8217 0.18457 17.3776 0.18457 14.7043C0.18457 12.1583 0.579198 9.91784 1.36845 7.98289C2.13225 6.02248 3.21429 4.44397 4.61459 3.24736C6.01488 2.05075 7.63158 1.26149 9.46469 0.879591V9.66324C8.31899 9.9433 7.40244 10.5289 6.71502 11.42C6.02761 12.3111 5.6839 13.3931 5.6839 14.6661C5.6839 16.321 6.21856 17.6704 7.28787 18.7142C8.33172 19.7581 9.71929 20.28 11.4506 20.28C13.1309 20.28 14.5185 19.7708 15.6132 18.7524C16.6826 17.734 17.2172 16.3974 17.2172 14.7425C17.2172 13.444 16.8735 12.3493 16.1861 11.4582C15.4987 10.5671 14.5948 9.9815 13.4746 9.70144V0.91778C15.2059 1.19784 16.7717 1.96163 18.172 3.20916C19.5723 4.45669 20.6798 6.08613 21.4945 8.09746C22.3092 10.0833 22.7165 12.2856 22.7165 14.7043C22.7165 17.3521 22.2328 19.7835 21.2653 21.9985C20.2724 24.2135 18.923 25.9703 17.2172 27.2687C15.4859 28.5417 13.5637 29.1782 11.4506 29.1782Z"
          fill={color}
        />
      </svg>

      {/* O */}
      <svg
        width="23"
        height="30"
        viewBox="0 0 23 30"
        fill="none"
        className="hidden"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.03125 14.5249C0.03125 11.7752 0.514986 9.2929 1.48246 7.07789C2.44994 4.86288 3.79931 3.13161 5.53058 1.88408C7.23639 0.636543 9.15861 0.0127773 11.2972 0.0127773C13.4104 0.0127773 15.3326 0.649271 17.0639 1.92226C18.7697 3.1698 20.1063 4.90107 21.0738 7.11608C22.0413 9.30562 22.525 11.7752 22.525 14.5249C22.525 17.1727 22.0413 19.6551 21.0738 21.9719C20.0809 24.2888 18.7315 26.1473 17.0257 27.5476C15.2944 28.9224 13.3849 29.6099 11.2972 29.6099C9.18407 29.6099 7.27458 28.9224 5.56877 27.5476C3.8375 26.1473 2.48812 24.3015 1.52065 22.0101C0.527716 19.6932 0.03125 17.1982 0.03125 14.5249ZM17.0639 14.5249C17.0639 13.5065 16.8475 12.5645 16.4147 11.6988C15.9818 10.8078 15.3326 10.0949 14.467 9.56023C13.5759 9.02557 12.5193 8.75824 11.2972 8.75824C10.0752 8.75824 9.03131 9.02557 8.16567 9.56023C7.27458 10.0949 6.61262 10.8078 6.1798 11.6988C5.74699 12.5645 5.53058 13.5065 5.53058 14.5249C5.53058 15.5178 5.75972 16.498 6.21799 17.4655C6.65081 18.4075 7.30004 19.1841 8.16567 19.7951C9.03131 20.4061 10.0752 20.7116 11.2972 20.7116C12.5193 20.7116 13.5632 20.4061 14.4288 19.7951C15.2944 19.1841 15.9564 18.4075 16.4147 17.4655C16.8475 16.498 17.0639 15.5178 17.0639 14.5249Z"
          fill={color}
        />
      </svg>

      {/* P */}
      <svg
        width="29"
        height="30"
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.7935 0.788509C13.6011 0.83943 15.3197 1.2977 16.9491 2.16334C18.5785 3.00352 19.9025 4.18741 20.9209 5.715C21.9138 7.24259 22.4103 8.97385 22.4103 10.9088C22.4103 15.3134 21.4173 18.5977 19.4314 20.7618H28.9789V29.5454H0.909404L0.909404 21.4874L3.58269 20.9909C1.39314 18.8523 0.349286 15.4916 0.451125 10.9088C0.451125 8.94839 0.934863 7.21713 1.90234 5.715C2.86981 4.18741 4.19372 2.99079 5.87408 2.12515C7.55443 1.25952 9.43846 0.813969 11.5262 0.788509H11.7935ZM17.2164 15.3006C17.2164 13.5184 16.6691 12.1436 15.5743 11.1761C14.454 10.1832 13.1301 9.66127 11.6025 9.61035C9.84582 9.66127 8.40733 10.1705 7.2871 11.1379C6.1414 12.0799 5.56856 13.4675 5.56856 15.3006C5.56856 16.3445 5.83589 17.2865 6.37054 18.1267C6.87974 18.9414 7.57989 19.5906 8.47098 20.0743C9.33662 20.5326 10.2659 20.7618 11.2588 20.7618H11.3352C13.1174 20.7618 14.5432 20.2653 15.6125 19.2723C16.6818 18.254 17.2164 16.9301 17.2164 15.3006Z"
          fill={color}
        />
      </svg>

      {/* I */}
      <svg
        width="29"
        height="10"
        viewBox="0 0 29 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.462891 0.463908L0.462891 9.24756H6.22955L6.22955 0.463908H0.462891ZM28.7233 0.463908V9.24756L7.33705 9.24756L7.33705 0.463908L28.7233 0.463908Z"
          fill={color}
        />
      </svg>
      {/* H */}
      <svg
        width="29"
        height="28"
        viewBox="0 0 29 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.22248 9.91903C7.22248 6.91476 8.24088 4.52154 10.2777 2.73935C12.3145 0.931696 15.5988 0.0278702 20.1306 0.0278702H28.7233V8.81152H20.2452C18.6412 8.81152 17.33 8.90063 16.3117 9.07885C15.2933 9.25707 14.4404 9.68989 13.7529 10.3773C13.0655 11.0647 12.7218 12.134 12.7218 13.5852C12.7218 15.1128 13.0782 16.2458 13.7911 16.9841C14.4785 17.7225 15.3442 18.1935 16.388 18.3972C17.4064 18.6008 18.6794 18.7027 20.207 18.7027H28.7233V27.4863H0.462891L0.462891 18.7027H10.4304C8.2918 16.7677 7.22248 13.8398 7.22248 9.91903Z"
          fill={color}
        />
      </svg>
      {/* logo */}
      <svg
        width="93"
        height="109"
        viewBox="0 0 93 109"
        fill="none"
        id="logo"
        xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_977_16" maskUnits="userSpaceOnUse" x="71" y="3" width="17" height="15">
          <path
            d="M71.167 11.2946C76.7018 7.4291 83.1127 11.9482 84.5711 17.521C88.8652 15.2516 88.9068 9.4746 84.4386 4.88068C79.085 1.46666 71.8305 4.84326 71.167 11.2946Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_977_16)">
          <path d="M71.167 1.46666V17.521H88.9068V1.46666H71.167Z" fill={color} />
        </g>
        <mask id="mask1_977_16" maskUnits="userSpaceOnUse" x="71" y="6" width="17" height="12">
          <path
            d="M71.167 11.2946C76.7018 7.4291 83.1127 11.9482 84.5711 17.521C87.3361 16.06 88.3373 13.1442 87.5076 10.0269C87.1559 9.79205 86.7896 9.55981 86.4051 9.33121C75.7427 2.9978 71.167 11.2946 71.167 11.2946Z"
            fill="white"
          />
        </mask>
        <mask id="mask2_977_16" maskUnits="userSpaceOnUse" x="67" y="4" width="26" height="27">
          <path
            d="M86.58 11.2904C86.58 17.1671 81.8162 21.9309 75.9394 21.9309C72.7608 21.9309 69.9079 20.537 67.958 18.3273C68.8387 19.6101 69.6034 20.9786 70.2368 22.4183C72.4885 27.2403 77.3812 30.5811 83.0543 30.5811C86.0989 30.5811 88.919 29.6188 91.2269 27.9812C91.9382 25.8744 92.3242 23.6169 92.3242 21.2701C92.3242 14.6363 89.2432 8.72222 84.4337 4.88061C85.7809 6.66322 86.58 8.88329 86.58 11.2904Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_977_16)">
          <path d="M67.958 4.88013V30.5806H92.3237V4.88013H67.958Z" fill={color} />
        </g>
        <mask id="mask3_977_16" maskUnits="userSpaceOnUse" x="67" y="4" width="23" height="26">
          <path
            d="M86.58 11.2903C86.58 17.167 81.8162 21.9308 75.9394 21.9308C72.7608 21.9308 69.9079 20.5368 67.958 18.3271C68.8387 19.6099 69.6034 20.9784 70.2368 22.4181C71.7539 25.6659 74.4686 28.2419 77.813 29.5776C97.3868 21.922 86.0033 5.7632 84.4332 4.88099C85.7809 6.66307 86.58 8.88314 86.58 11.2903Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_977_16)">
          <path d="M67.958 4.88099V29.5776L97.3868 29.5776V4.88099L67.958 4.88099Z" fill={color} />
        </g>
        <mask id="mask4_977_16" maskUnits="userSpaceOnUse" x="5" y="0" width="21" height="40">
          <path
            d="M25.5925 25.8581L22.2866 1.06271C15.8529 -1.12723 15.8597 7.44028 11.192 11.6134C0.543618 21.1337 6.51334 39.3079 21.2625 39.9932C21.7842 39.4637 22.3271 38.9556 22.8893 38.4693C21.7239 33.6837 24.9857 29.27 25.5925 25.8581Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask4_977_16)">
          <path
            d="M0.544922 -1.12723L0.544922 39.9932H25.5938L25.5938 -1.12723H0.544922Z"
            fill={color}
          />
        </g>
        <mask id="mask5_977_16" maskUnits="userSpaceOnUse" x="22" y="7" width="70" height="36">
          <path
            d="M66.7061 16.6586C58.4649 6.56252 43.792 4.46299 33.0871 11.8116C24.4609 17.7304 20.426 28.3585 22.8882 38.4686C43.7219 20.4456 57.2631 42.2354 71.3577 42.2354C80.5892 42.2354 88.4272 36.2688 91.2256 27.9818C88.9177 29.6189 86.0981 30.5817 83.0529 30.5817C74.8569 30.5812 71.021 21.944 66.7061 16.6586Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask5_977_16)">
          <path
            d="M20.4258 4.46351L20.4258 42.2354L91.2254 42.2354V4.46351L20.4258 4.46351Z"
            fill={color}
          />
        </g>
        <mask id="mask6_977_16" maskUnits="userSpaceOnUse" x="0" y="10" width="52" height="43">
          <path
            d="M21.2624 39.9914C12.6351 39.5903 5.76345 32.4687 5.76345 23.7422C5.76345 22.9416 5.82112 22.1545 5.93283 21.3845C6.33237 18.6339 7.4198 16.1063 9.01537 13.9818C7.4276 13.0144 6.01908 11.7815 4.85111 10.3454C1.79507 14.6692 0 19.9469 0 25.6443C0 39.8438 11.1528 51.4388 25.1767 52.147C29.3415 42.942 38.6062 36.5369 49.3668 36.5369C49.823 36.5369 50.2766 36.5483 50.7275 36.5712C51.0424 35.8755 51.3276 35.1642 51.5827 34.4378C43.3275 30.6248 34.1791 28.7004 22.8896 38.467C22.3269 38.9538 21.784 39.462 21.2624 39.9914Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask6_977_16)">
          <path d="M0 10.3449L0 52.147H51.5827V10.3449H0Z" fill={color} />
        </g>
        <mask id="mask7_977_16" maskUnits="userSpaceOnUse" x="32" y="18" width="25" height="39">
          <path
            d="M43.5857 18.9486C39.8745 30.9265 36.1633 42.9044 32.4521 54.8828C43.579 57.3377 51.4264 56.2103 54.3286 51.316C59.8032 42.0851 51.762 23.5275 43.5857 18.9486Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask7_977_16)">
          <path d="M32.4531 18.9484V57.3369H59.8037V18.9484H32.4531Z" fill={color} />
        </g>
        <mask id="mask8_977_16" maskUnits="userSpaceOnUse" x="71" y="92" width="17" height="15">
          <path
            d="M71.167 98.3817C76.7018 102.247 83.1127 97.728 84.5711 92.1553C88.8652 94.4247 88.9068 100.202 84.4386 104.796C79.085 108.21 71.8305 104.833 71.167 98.3817Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask8_977_16)">
          <path d="M71.167 108.21V92.1553H88.9068V108.21H71.167Z" fill={color} />
        </g>
        <mask id="mask9_977_16" maskUnits="userSpaceOnUse" x="71" y="92" width="17" height="11">
          <path
            d="M71.167 98.3817C76.7018 102.247 83.1127 97.728 84.5711 92.1553C87.3361 93.6163 88.3373 96.532 87.5076 99.6494C87.1559 99.8842 86.7896 100.116 86.4051 100.345C75.7427 106.678 71.167 98.3817 71.167 98.3817Z"
            fill="white"
          />
        </mask>
        <mask id="mask10_977_16" maskUnits="userSpaceOnUse" x="67" y="79" width="26" height="26">
          <path
            d="M86.58 98.3849C86.58 92.5082 81.8162 87.7444 75.9394 87.7444C72.7608 87.7444 69.9079 89.1383 67.958 91.348C68.8387 90.0652 69.6034 88.6967 70.2368 87.257C72.4885 82.435 77.3812 79.0942 83.0543 79.0942C86.0989 79.0942 88.919 80.0565 91.2269 81.6941C91.9382 83.8009 92.3242 86.0584 92.3242 88.4052C92.3242 95.0389 89.2432 100.953 84.4337 104.795C85.7809 103.012 86.58 100.792 86.58 98.3849Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask10_977_16)">
          <path d="M67.958 104.795V79.0942H92.3237V104.795H67.958Z" fill={color} />
        </g>
        <mask id="mask11_977_16" maskUnits="userSpaceOnUse" x="67" y="80" width="23" height="25">
          <path
            d="M86.58 98.3855C86.58 92.5088 81.8162 87.745 75.9394 87.745C72.7608 87.745 69.9079 89.139 67.958 91.3486C68.8387 90.0659 69.6034 88.6973 70.2368 87.2577C71.7539 84.0099 74.4686 81.4339 77.813 80.0981C97.3868 87.7538 86.0033 103.913 84.4332 104.795C85.7809 103.013 86.58 100.793 86.58 98.3855Z"
            fill="white"
          />
        </mask>
        <mask id="mask12_977_16" maskUnits="userSpaceOnUse" x="5" y="69" width="21" height="40">
          <path
            d="M25.5925 83.8192L22.2866 108.615C15.8529 110.804 15.8597 102.237 11.192 98.0639C0.543618 88.5435 6.51334 70.3694 21.2625 69.6841C21.7842 70.2135 22.3271 70.7216 22.8893 71.2079C21.7239 75.9936 24.9857 80.4072 25.5925 83.8192Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask12_977_16)">
          <path
            d="M0.544922 110.804L0.544922 69.6836H25.5938L25.5938 110.804H0.544922Z"
            fill={color}
          />
        </g>
        <mask id="mask13_977_16" maskUnits="userSpaceOnUse" x="22" y="67" width="70" height="36">
          <path
            d="M66.7061 93.0182C58.4649 103.114 43.792 105.214 33.0871 97.8651C24.4609 91.9463 20.426 81.3183 22.8882 71.2082C43.7219 89.2311 57.2631 67.4414 71.3577 67.4414C80.5892 67.4414 88.4272 73.408 91.2256 81.6949C88.9177 80.0578 86.0981 79.0951 83.0529 79.0951C74.8569 79.0956 71.021 87.7327 66.7061 93.0182Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask13_977_16)">
          <path d="M20.4258 105.213L20.4258 67.4414H91.2254V105.213H20.4258Z" fill={color} />
        </g>
        <mask id="mask14_977_16" maskUnits="userSpaceOnUse" x="0" y="57" width="52" height="43">
          <path
            d="M21.2624 69.6839C12.6351 70.085 5.76345 77.2066 5.76345 85.9331C5.76345 86.7337 5.82112 87.5208 5.93283 88.2908C6.33237 91.0413 7.4198 93.569 9.01537 95.6935C7.4276 96.6609 6.01908 97.8938 4.85111 99.3299C1.79507 95.0061 0 89.7284 0 84.031C0 69.8314 11.1528 58.2365 25.1767 57.5283C29.3415 66.7333 38.6062 73.1384 49.3668 73.1384C49.823 73.1384 50.2766 73.127 50.7275 73.1041C51.0424 73.7998 51.3276 74.5111 51.5827 75.2374C43.3275 79.0505 34.1791 80.9749 22.8896 71.2083C22.3269 70.7214 21.784 70.2133 21.2624 69.6839Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask14_977_16)">
          <path d="M0 99.3304L0 57.5283H51.5827V99.3304H0Z" fill={color} />
        </g>
        <mask id="mask15_977_16" maskUnits="userSpaceOnUse" x="32" y="53" width="25" height="38">
          <path
            d="M43.5857 90.7267C39.8745 78.7488 36.1633 66.7709 32.4521 54.7925C43.579 52.3376 51.4264 53.465 54.3286 58.3592C59.8032 67.5902 51.762 86.1478 43.5857 90.7267Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask15_977_16)">
          <path d="M32.4531 90.7269V52.3384H59.8037V90.7269H32.4531Z" fill={color} />
        </g>
      </svg>
    </div>
  );
}

BadgeLogo.propTypes = {
  color: PropTypes.string
};

export default BadgeLogo;

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'motion/react';

import './index.css';

const Face = ({ sliderValue, visible }) => {
  const SLIDER_STATES = {
    1: 'bad',
    2: 'normal',
    3: 'good'
  };

  const mouthVariants = {
    visible: {
      opacity: 1
    },
    hidden: {
      opacity: 0
    }
  };

  const eyesVariants = {
    visible: {
      width: '250px'
    },
    hidden: {
      width: '180px'
    }
  };

  const leftEyeVariants = {
    hidden: {
      scaleX: 1,
      scaleY: 1
    },
    bad: {
      scaleX: 1,
      scaleY: 1
    },
    normal: {
      scaleX: -1,
      scaleY: -1
    },
    good: {
      scaleX: 1,
      scaleY: 1
    }
  };

  const rightEyeVariants = {
    hidden: {
      scaleX: 1,
      scaleY: 1
    },
    bad: {
      scaleX: -1,
      scaleY: 1
    },
    normal: {
      scaleY: -1,
      scaleX: 1
    },
    good: {
      scaleX: 1,
      scaleY: 1
    }
  };

  const renderMouth = (value) => {
    switch (value) {
      case 1:
        return (
          <motion.img
            key={value}
            className="face__mouth"
            animate={{ opacity: [0, 1], scale: [0, 1] }}
            exit={{ opacity: 0, scale: 0 }}
            src="./assets/images/downward.png"
            style={{ width: '212.46px', paddingTop: '54.065px' }}></motion.img>
        );
      case 2:
      default:
        return (
          <motion.img
            key={value}
            className="face__mouth"
            animate={{ opacity: [0, 1], scale: [0, 1] }}
            exit={{ opacity: 0, scale: 0 }}
            src="./assets/images/straight.png"
            style={{
              width: '212.46px',
              paddingTop: '70.88px',
              paddingBottom: '15.475px'
            }}></motion.img>
        );
      case 3:
        return (
          <motion.img
            key={value}
            className="face__mouth"
            animate={{ opacity: [0, 1], scale: [0, 1] }}
            exit={{ opacity: 0, scale: 0 }}
            src="./assets/images/upward.png"
            style={{ width: '212.46px', paddingTop: '55.395px' }}></motion.img>
        );
    }
  };

  return (
    <div className="face__wrap">
      <motion.div
        variants={eyesVariants}
        initial="hidden"
        animate={visible ? 'visible' : 'hidden'}
        className="face__eyes">
        <motion.img
          variants={leftEyeVariants}
          animate={!visible ? 'hidden' : SLIDER_STATES[sliderValue]}
          src="./assets/images/G.png"
          className="face__eye"></motion.img>
        <motion.img
          variants={rightEyeVariants}
          animate={!visible ? 'hidden' : SLIDER_STATES[sliderValue]}
          src="./assets/images/G.png"
          className="face__eye"></motion.img>
      </motion.div>

      <motion.div
        className="face__mouth-wrap"
        initial="hidden"
        variants={mouthVariants}
        animate={visible ? 'visible' : 'hidden'}>
        {renderMouth(sliderValue)}
      </motion.div>
    </div>
  );
};

Face.propTypes = {
  sliderValue: PropTypes.number,
  visible: PropTypes.bool
};

export default Face;

import { React, useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

import './index.css';

const Loader = () => {
  const [hidden, setHidden] = useState(false);
  const [dragStartY, setDragStartY] = useState(null);

  const handlers = useSwipeable({
    onSwipedUp: () => {
      setHidden(true);
    }
  });

  useEffect(() => {
    if (dragStartY !== null) {
      const onMouseMove = (e) => {
        const diff = dragStartY - e.clientY;
        if (diff > 150) {
          setHidden(true);
        }
      };

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
        setDragStartY(null);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);

      return () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };
    }
  }, [dragStartY]);

  const onMouseDown = (e) => {
    setDragStartY(e.clientY);
  };

  return (
    <div
      {...handlers}
      onMouseDown={onMouseDown}
      className={`loader__content ${hidden ? 'hidden' : ''}`}>
      <h1 className="loader__title">Hipocampus</h1>

      <div className="loader__button">
        <img src="./assets/images/arrow.png" alt="Swipe Up" className="loader__button-arrow" />
        <p className="loader__button-desc">Swipe up to say “Hi” for Hipocampus</p>
      </div>
    </div>
  );
};

export default Loader;

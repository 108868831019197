import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import apiService from '../../services';

import './index.css';

import { CompleteButton, CustomTextField } from '../../styles/styled-components';
import Line from '../../components/Line';
import Light from '../../components/Light';
import { InputAdornment } from '@mui/material';
import Projector from '../../components/Projector';

const Answers = ({ onNextPageClick }) => {
  const questions = [...Array(7)].map((_, index) => index);

  const initialData = questions.reduce(
    (acc, value) => ({
      ...acc,
      [`q${value + 1}`]: ''
    }),
    {}
  );

  const activeAddsByRound = [1, 2, 3, 1, 2, 3, 7];

  const [activeRound, setActiveRound] = useState(0);

  const [roundChanging, setRoundChanging] = useState(false);

  const [loading, setLoading] = useState(false);

  const [completeDisabled, setCompleteDisabled] = useState(true);

  const [activeQuestion, setActiveQuestion] = useState(0);

  const [formData, setFormData] = useState(initialData);

  const [activeAdds, setActiveAdds] = useState([]);

  const [notificationActive, setNotificationActive] = useState(false);

  const colors = ['#B12CFF', '#0057FF', '#1AC99B', '#47CF15', '#CFCD0E', '#D7089D', '#D61030'];

  const roundChangeTimerRef = useRef(null);

  const setRoundChangingTimerRef = useRef(null);

  const setAddNotificationRef = useRef(null);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    localStorage.setItem(`round${activeRound + 1}`, JSON.stringify(formData));
    localStorage.setItem(`round${activeRound + 1}__active`, JSON.stringify(activeAdds));
    setCompleteDisabled(Object.values(formData).some((value) => !value.length));
  }, [formData, activeAdds]);

  useEffect(() => {
    clearTimeout(roundChangeTimerRef.current);
    clearTimeout(setRoundChangingTimerRef.current);
  }, []);

  const onSendAnswers = async () => {
    setLoading(true);

    try {
      const { password, teamName } = JSON.parse(localStorage.getItem('teamData'));

      let data = {
        team: teamName,
        code: password,
        round: activeRound + 1
      };

      Object.entries(formData).forEach(([key, value]) => {
        data = {
          ...data,
          [key]: `${value}${activeAdds.includes(key) ? '  💚' : ''}`
        };
      });

      await apiService.postRoundData(data);

      setLoading(false);
      proceedRoundChange();
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };

  const proceedRoundChange = () => {
    setRoundChanging(true);

    if (activeRound === 6) {
      onNextPageClick();
    } else {
      roundChangeTimerRef.current = setTimeout(() => {
        setActiveQuestion(0);
        setActiveRound(activeRound + 1);
        setFormData(initialData);
        setActiveAdds([]);
      }, 1200);

      setRoundChangingTimerRef.current = setTimeout(() => {
        setRoundChanging(false);
      }, 2000);
    }
  };

  const getRoundTitle = (round) => {
    return round + 1 === 7 ? 'Final round' : `Round ${round + 1}`;
  };

  const toggleActiveAdd = (question) => {
    if (activeAdds.includes(question)) {
      setActiveAdds([...activeAdds.filter((item) => item !== question)]);
    } else {
      if (activeAdds.length < activeAddsByRound[activeRound]) {
        setActiveAdds([...activeAdds, question]);
      } else {
        if (!notificationActive) {
          setNotificationActive(true);
          setAddNotificationRef.current = setTimeout(() => {
            setNotificationActive(false);
          }, 2000);
        }
      }
    }
  };

  return (
    <div className="content answers__сontent">
      <div
        className={`answers__add-notification ${notificationActive ? 'active' : ''}`}
        style={{
          backgroundColor: colors[activeRound]
        }}>
        <span className="answers__add-notification-info">
          {getRoundTitle(activeRound)} except only {activeAddsByRound[activeRound]} active
          &quot;+1&quot;
        </span>
      </div>
      <div
        className={`answers__changer ${roundChanging && activeRound !== 6 ? 'active' : ''}`}
        style={{
          backgroundColor: colors[activeRound + 1]
        }}>
        <div className="answers__changer-info">
          <h2>{getRoundTitle(activeRound + 1)}</h2>
          <p>3...2...1...</p>
        </div>
      </div>
      <div className="answers__steps-wrap">
        <div className="answers__steps-bg">
          {[...Array(6)].map((_, index) => (
            <div key={`bg_item${index}`} className={`answers__steps-bg-item item${index}`}>
              <Light color={colors[activeRound]} />
            </div>
          ))}
          <div className="answers__steps-bg-item item1"></div>
          <div className="answers__steps-bg-item item2"></div>
          <div className="answers__steps-bg-item item3"></div>
          <div className="answers__steps-bg-item item4"></div>
          <div className="answers__steps-bg-item item5"></div>
          <div className="answers__steps-bg-item item6"></div>
        </div>
        <div className={`answers__steps-line active__${activeQuestion}`}>
          <Line color={colors[activeRound]} bgcolor="rgba(255, 255, 255, 0.1)" />
        </div>
        <div
          className={`answers__steps-pointer active__${activeQuestion}`}
          style={{
            backgroundColor: colors[activeRound]
          }}>
          <img src="./assets/images/whiteLogo.png" alt="logo" />
        </div>
        {questions.map((question) => (
          <div key={question} className="answers__step">
            <div
              className={`answers__step-content ${question === activeQuestion ? 'active' : ''}`}
              style={{
                color: question === activeQuestion ? colors[activeRound] : '#ffffff'
              }}>
              <span className={`answers__step-number number__${question + 1}`}>{question + 1}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="answers__fields-wrap">
        <h2 className={`answers__fields-title ${activeRound + 1 === 7 ? 'final' : ''}`}>
          {getRoundTitle(activeRound)}
        </h2>
        <div className="answers__fields">
          {questions.map((question) => (
            <div key={question} className="answers__field">
              <div
                className={`anserts__field-content ${question === activeQuestion ? 'active' : ''}`}>
                <CustomTextField
                  key={`q${question + 1}`}
                  name={`q${question + 1}`}
                  value={formData[`q${question + 1}`]}
                  variant="outlined"
                  placeholder="The artist Salvador Dali"
                  bordercolor={colors[activeRound]}
                  answer="true"
                  onChange={handleChange}
                  active={question === activeQuestion ? 'true' : ''}
                  onFocus={() => {
                    setActiveQuestion(question);
                  }}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={() => toggleActiveAdd(`q${question + 1}`)}
                          className={activeAdds.includes(`q${question + 1}`) ? 'active' : ''}>
                          <Projector key={`q${question + 1}__add`} color={colors[activeRound]} />
                          <span className="anserts__field-add">+1</span>
                        </InputAdornment>
                      )
                    }
                  }}
                  fullWidth
                />
              </div>
            </div>
          ))}
        </div>
        <div className="answers__fields-submit">
          <CompleteButton
            variant="contained"
            bgcolor={colors[activeRound]}
            answers="true"
            fullWidth
            disabled={roundChanging || completeDisabled || loading}
            onClick={onSendAnswers}>
            Send answers
          </CompleteButton>
        </div>
      </div>
    </div>
  );
};

Answers.propTypes = {
  onNextPageClick: PropTypes.func.isRequired
};

export default Answers;

import React from 'react';
import PropTypes from 'prop-types';

function Light({ color }) {
  return (
    <svg
      width="172"
      height="172"
      viewBox="0 0 172 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_772_3262)">
        <circle cx="86" cy="86" r="36" fill={color} />
      </g>
      <defs>
        <filter
          id="filter0_f_772_3262"
          x="0.684933"
          y="0.684933"
          width="170.63"
          height="170.63"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feGaussianBlur stdDeviation="24.6575" result="effect1_foregroundBlur_772_3262" />
        </filter>
      </defs>
    </svg>
  );
}

Light.propTypes = {
  color: PropTypes.string
};

export default Light;

import { React, useState } from 'react';

import Registration from './pages/Registration';
import Answers from './pages/Answers';
import Rating from './pages/Rating';

const App = () => {
  const [currectPage, setCurrectPage] = useState(0);

  const handleNext = () => {
    setCurrectPage((prevStep) => Math.min(prevStep + 1, pages.length - 1));
  };

  const pages = [
    <Registration key="registration" onNextPageClick={handleNext} />,
    <Answers key="answers" onNextPageClick={handleNext} />,
    <Rating key="rating" onNextPageClick={handleNext} />
  ];

  return <div className="container">{pages[currectPage]}</div>;
};

export default App;

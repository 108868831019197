import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'motion/react';

const filterToRed =
  'brightness(0) saturate(100%) invert(34%) sepia(100%) saturate(7456%) hue-rotate(1deg) brightness(95%) contrast(124%)';
const filterToYellow =
  'brightness(0) saturate(100%) invert(46%) sepia(83%) saturate(1145%) hue-rotate(1deg) brightness(104%) contrast(103%)';
const filterToGreen =
  'brightness(0) saturate(100%) invert(42%) sepia(21%) saturate(5621%) hue-rotate(71deg) brightness(97%) contrast(101%)';

const renderBackground = (sliderValue) => {
  switch (sliderValue) {
    case 1:
      return (
        <div key={'G1'}>
          <motion.img
            key={'G1L'}
            src="./assets/images/largeElipse.png"
            style={{
              position: 'absolute',
              top: '-500px',
              left: '-700px',
              zIndex: -2,
              height: '1193px',
              maxWidth: 'unset'
            }}
            animate={{ filter: filterToRed, x: [-300, 0], y: [300, 0] }}
            transition={{ duration: 1, type: 'spring' }}
            exit={{ x: 600, y: -600 }}
          />
          <motion.img
            key={'G1S'}
            src="./assets/images/smallElipse.png"
            style={{
              position: 'absolute',
              bottom: '-280px',
              right: '-200px',
              zIndex: -2,
              height: '596px',
              maxWidth: 'unset'
            }}
            animate={{ filter: filterToRed, x: [300, 0], y: [-300, 0] }}
            transition={{ duration: 1, type: 'spring' }}
            exit={{ x: -300, y: 300 }}
          />
        </div>
      );
    case 2:
    default:
      return (
        <div key={'G2'}>
          <motion.img
            key={'G2L'}
            src="./assets/images/largeElipse.png"
            style={{
              position: 'absolute',
              top: '-500px',
              right: '-700px',
              zIndex: -2,
              height: '1193px',
              maxWidth: 'unset'
            }}
            animate={{ filter: filterToYellow, x: [-600, 0], y: [-600, 0] }}
            transition={{ duration: 1, type: 'spring' }}
            exit={{ x: 500, y: 600 }}
          />
          <motion.img
            key={'G2S'}
            src="./assets/images/smallElipse.png"
            style={{
              position: 'absolute',
              bottom: '-280px',
              left: '-200px',
              zIndex: -2,
              height: '596px',
              maxWidth: 'unset'
            }}
            animate={{ filter: filterToYellow, x: [350, 0], y: [350, 0] }}
            transition={{ duration: 1, type: 'spring' }}
            exit={{ x: -500, y: -600 }}
          />
        </div>
      );
    case 3:
      return (
        <div key={'G3'}>
          <motion.img
            key={'G3L'}
            src="./assets/images/largeElipse.png"
            style={{
              position: 'absolute',
              bottom: '-500px',
              right: '-700px',
              zIndex: -2,
              height: '1193px',
              maxWidth: 'unset'
            }}
            animate={{ filter: filterToGreen, x: [500, 0], y: [-500, 0] }}
            transition={{ duration: 1, type: 'spring' }}
            exit={{ x: 500, y: -600 }}
          />
          <motion.img
            key={'G3S'}
            src="./assets/images/smallElipse.png"
            style={{
              position: 'absolute',
              top: '-280px',
              left: '-200px',
              zIndex: -2,
              height: '596px',
              maxWidth: 'unset'
            }}
            animate={{ filter: filterToGreen, x: [-500, 0], y: [500, 0] }}
            transition={{ duration: 1, type: 'spring' }}
            exit={{ x: -500, y: 600 }}
          />
        </div>
      );
  }
};

const Background = ({ sliderValue }) => {
  return <AnimatePresence mode="sync">{renderBackground(sliderValue)}</AnimatePresence>;
};

Background.propTypes = {
  sliderValue: PropTypes.number
};

export default Background;

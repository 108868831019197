import { Button, TextField, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CompleteButton = styled(Button)(({ bgcolor, answers }) => ({
  boxShadow: 'none',
  fontSize: answers ? '14px' : '16px',
  fontFamily: 'Gilroy',
  backgroundColor: bgcolor,
  textTransform: 'none',
  marginTop: answers ? '0' : '20px',
  padding: '10px',
  transition: 'all 0.5s ease',
  '&.Mui-disabled': {
    backgroundColor: bgcolor,
    color: '#FFFFFF',
    opacity: '0.4'
  }
}));

export const CustomTextField = styled(TextField)(({ bordercolor, answer, active }) => ({
  margin: answer ? '0' : '20px 0',
  position: 'relative',

  '& .MuiInputAdornment-root': {
    position: 'absolute',
    right: '5px',
    top: '0px',
    height: 'calc(100% - 10px)',
    maxHeight: 'none',
    borderTop: `1px solid inherit`,
    zIndex: '5',
    paddingTop: '1px',

    '& svg': {
      width: '100%',
      height: '100%',
      transition: 'all 1s ease',

      '& path': {
        fill: 'url(#paint0_linear_772_3210)'
      }
    },

    '&.active': {
      borderTop: `1px solid ${bordercolor}`,

      '& .anserts__field-add': {
        opacity: '1'
      },
      '& svg path': {
        fillOpacity: '1',
        fill: 'url(#paint0_linear_772_3211)'
      }
    }
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Gilroy',
    color: '#FFFFFF',
    paddingRight: '30px',
    '& fieldset': {
      borderColor: active ? bordercolor : '#FFFFFF'
    },
    '&:hover fieldset': {
      borderColor: active ? bordercolor : '#FFFFFF'
    },
    '&.Mui-focused fieldset': {
      borderColor: bordercolor
    }
  },
  '& .MuiInputBase-input': {
    color: '#FFFFFF',
    fontSize: answer ? '14px' : '16px',
    lineHeight: '1em',
    padding: answer ? '16px' : '14px'
  },
  '& .MuiInputLabel-root': {
    color: '#FFFFFF',
    fontFamily: 'Gilroy',
    '&.Mui-focused': {
      color: '#FFFFFF'
    }
  }
}));

export const CustomToggleButtonGroup = styled(ToggleButtonGroup)({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '15px 0'
});

export const CustomToggleButton = styled(ToggleButton)(({ bordercolor }) => ({
  width: '80%',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  color: '#FFFFFF',
  fontSize: '14px',
  lineHeight: '20px',
  padding: '0',
  margin: '0',
  border: '0.5px solid #FFFFFF',
  borderRadius: '5px',
  transition: 'all 0.5s ease',
  fontFamily: 'Gilroy',

  '&.Mui-selected': {
    color: bordercolor,
    borderColor: bordercolor,
    borderRadius: '5px'
  },
  '&.MuiToggleButtonGroup-firstButton:not(.Mui-selected)': {
    border: '0.5px solid #FFFFFF',
    borderRadius: '5px'
  },
  '&.MuiToggleButtonGroup-middleButton:not(.Mui-selected)': {
    border: '0.5px solid #FFFFFF',
    borderRadius: '5px'
  },
  '&.MuiToggleButtonGroup-lastButton:not(.Mui-selected)': {
    border: '0.5px solid #FFFFFF',
    borderRadius: '5px'
  }
}));

import React from 'react';
import PropTypes from 'prop-types';

function Projector({ color }) {
  return (
    <svg width="80" height="97" viewBox="0 0 80 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25 0H55L80 97H0L25 0Z" fill="url(#paint0_linear_772_3210)" />
      <defs>
        <linearGradient
          id="paint0_linear_772_3210"
          x1="40"
          y1="0"
          x2="40"
          y2="97"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2B2B2B80" />
          <stop offset="1" stopColor="#2B2B2B80" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint0_linear_772_3211"
          x1="40"
          y1="0"
          x2="40"
          y2="97"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

Projector.propTypes = {
  color: PropTypes.string
};

export default Projector;

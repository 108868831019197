import { React } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'motion/react';

import Slider, { SliderThumb } from '@mui/material/Slider';

const MARKS = [
  {
    value: 1,
    label: 'Bad',
    filter:
      'brightness(0) saturate(100%) invert(34%) sepia(100%) saturate(7456%) hue-rotate(1deg) brightness(95%) contrast(124%)'
  },
  {
    value: 2,
    label: 'Normal',
    filter:
      'brightness(0) saturate(100%) invert(46%) sepia(83%) saturate(1145%) hue-rotate(1deg) brightness(104%) contrast(103%)'
  },
  {
    value: 3,
    label: 'Good',
    filter:
      'brightness(0) saturate(100%) invert(42%) sepia(21%) saturate(5621%) hue-rotate(71deg) brightness(97%) contrast(101%)'
  }
];

const customThumb = (props) => {
  const { children, ...other } = props;

  const { value } = children.props;

  const filter = MARKS.find((mark) => mark.value === value)?.filter || MARKS[0].filter;

  return (
    <SliderThumb
      className="slider"
      sx={{
        color: 'transparent',
        width: '56px',
        height: '56px',
        '&:hover': {
          boxShadow: 'none'
        },
        '&.Mui-focusVisible': {
          boxShadow: 'none'
        },
        '&:after': {
          display: 'none'
        },
        '&:before': {
          display: 'none'
        }
      }}
      {...other}>
      {children}
      <motion.img
        className="sliderthumb__img"
        animate={{ filter }}
        style={{ height: '100%' }}
        src="./assets/images/blackLogo.png"></motion.img>
    </SliderThumb>
  );
};

const CustomSlider = ({ onSliderChange, visible }) => {
  return (
    <Slider
      className={!visible && 'hidden'}
      sx={{
        width: '200px',
        color: 'white',
        margin: 0,
        '& .MuiSlider-markLabel': {
          color: 'transparent',
          fontFamily: 'Gilroy',
          marginTop: '25.21px',
          fontSize: '18px'
        },
        '& .MuiSlider-mark': { opacity: 0 },
        '& .MuiSlider-track': { opacity: 0 },
        '& .MuiSlider-rail': { color: '#0E0E0E', border: '1px solid #424242' },
        '&.hidden': {
          '.MuiSlider-rail': {
            color: 'transparent',
            border: '0px'
          },
          '.sliderthumb__img': {
            filter: 'invert(100%) !important'
          }
        }
      }}
      disabled={!visible}
      defaultValue={2}
      step={1}
      marks={MARKS}
      min={1}
      max={3}
      slots={{ thumb: customThumb }}
      onChange={(_, value) => onSliderChange(value)}
    />
  );
};

CustomSlider.propTypes = {
  onSliderChange: PropTypes.func.isRequired,
  visible: PropTypes.bool
};

export default CustomSlider;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import apiService from '../../services';

import './index.css';

import Loader from '../../components/Loader';
import Background from '../../components/Backgroud';
import PlayersNumber from '../../components/PlayersNumber';

import { CustomTextField, CompleteButton } from '../../styles/styled-components';
import BadgeLogo from '../../components/BadgeLogo';

function Registration({ onNextPageClick }) {
  const colors = ['#FF0000', '#FF8400', '#2F9E00'];

  const [disabled, setDisabled] = useState(true);

  const [loading, setLoading] = useState(false);

  const [stepValue, setStepValue] = useState(1);

  const [formData, setFormData] = useState({
    teamName: '',
    password: '',
    playersNumber: 0
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    const teamData = JSON.parse(localStorage.getItem('teamData'));
    if (teamData) {
      setFormData({
        ...teamData
      });
    }
  }, []);

  useEffect(() => {
    setDisabled(Object.values(formData).some((value) => !value?.toString().length));
    localStorage.setItem('teamData', JSON.stringify(formData));
  }, [formData]);

  const completeHandler = async () => {
    setLoading(true);

    try {
      const { password, teamName, playersNumber } = formData;

      const data = {
        code: password,
        team: teamName,
        plrs: playersNumber
      };

      await apiService.postTeamData(data);

      setLoading(false);
      onNextPageClick();
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Loader />
      <Background sliderValue={stepValue} />
      <div className="content registration__content">
        <div className="registration__form">
          <div className="registration__badge-wrap">
            <img className="registration__badge" src="./assets/images/badge1.png" alt="badge" />
            <BadgeLogo color={colors[stepValue - 1]} />
          </div>
          <CustomTextField
            label="Team Name"
            name="teamName"
            variant="outlined"
            value={formData.teamName}
            placeholder="Enter team name"
            bordercolor={colors[0]}
            onChange={handleChange}
            onFocus={() => {
              setStepValue(1);
            }}
            fullWidth
          />
          <CustomTextField
            label="Password"
            name="password"
            variant="outlined"
            value={formData.password}
            placeholder="Enter password"
            bordercolor={colors[1]}
            onChange={handleChange}
            onFocus={() => {
              setStepValue(2);
            }}
            fullWidth
          />
          <PlayersNumber
            customTextField={CustomTextField}
            bordercolor={colors[2]}
            value={formData.playersNumber}
            onChangeNumber={(number) => {
              setFormData({
                ...formData,
                playersNumber: number
              });
              setStepValue(3);
            }}
          />
          <CompleteButton
            variant="contained"
            bgcolor={colors[stepValue - 1]}
            fullWidth
            disabled={loading || disabled}
            onClick={completeHandler}>
            Complete registration
          </CompleteButton>
        </div>
      </div>
    </>
  );
}

Registration.propTypes = {
  onNextPageClick: PropTypes.func.isRequired
};

export default Registration;

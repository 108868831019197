import { React, useState } from 'react';
import { motion, AnimatePresence } from 'motion/react';
import './index.css';

import CustomSlider from '../../components/CustomSlider';
import Face from '../../components/Face';
import Background from '../../components/Backgroud';

function Rating() {
  const [visible, setVisible] = useState(false);
  const [sliderValue, setSliderValue] = useState(2);

  const SLIDER_STATES = {
    1: 'bad',
    2: 'normal',
    3: 'good'
  };

  const variants = {
    visible: {
      opacity: 1
    },
    hidden: {
      opacity: 0
    }
  };

  const feedbackVariants = {
    bad: {
      backgroundColor: '#FF0000',
      borderTopLeftRadius: '250px',
      borderTopRightRadius: '250px',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0'
    },
    normal: {
      backgroundColor: '#FF8400',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0'
    },
    good: {
      backgroundColor: '#2F9E00',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
      borderBottomLeftRadius: '250px',
      borderBottomRightRadius: '250px'
    }
  };

  const renderMarkLabel = (sliderValue) => {
    switch (sliderValue) {
      case 1:
        return (
          <div style={{ paddingTop: '0px' }} className="divFlex">
            <div style={{ flexDirection: 'row', width: '200px' }}>
              <motion.span
                key={'B1'}
                className="markLabel"
                style={{ left: '-8%' }}
                animate={{ color: '#FF0000' }}>
                Bad
              </motion.span>
              <motion.span
                key={'N1'}
                className="markLabel"
                style={{ left: '18%' }}
                animate={{ color: 'white' }}>
                Normal
              </motion.span>
              <motion.span
                key={'G1'}
                className="markLabel"
                style={{ left: '42%' }}
                animate={{ color: 'white' }}>
                Good
              </motion.span>
            </div>
          </div>
        );
      case 2:
      default:
        return (
          <div style={{ paddingTop: '0px' }} className="divFlex">
            <div style={{ flexDirection: 'row', width: '200px' }}>
              <motion.span
                key={'B2'}
                className="markLabel"
                style={{ left: '-8%' }}
                animate={{ color: 'white' }}>
                Bad
              </motion.span>
              <motion.span
                key={'N2'}
                className="markLabel"
                style={{ left: '18%' }}
                animate={{ color: '#FF8400' }}>
                Normal
              </motion.span>
              <motion.span
                key={'G2'}
                className="markLabel"
                style={{ left: '42%' }}
                animate={{ color: 'white' }}>
                Good
              </motion.span>
            </div>
          </div>
        );
      case 3:
        return (
          <div style={{ paddingTop: '0px' }} className="divFlex">
            <div style={{ flexDirection: 'row', width: '200px' }}>
              <motion.span
                key={'B3'}
                className="markLabel"
                style={{ left: '-8%' }}
                animate={{ color: 'white' }}>
                Bad
              </motion.span>
              <motion.span
                key={'N3'}
                className="markLabel"
                style={{ left: '18%' }}
                animate={{ color: 'white' }}>
                Normal
              </motion.span>
              <motion.span
                key={'G3'}
                className="markLabel"
                style={{ left: '42%' }}
                animate={{ color: '#2F9E00' }}>
                Good
              </motion.span>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <motion.div variants={variants} animate={visible ? 'visible' : 'hidden'}>
        <Background sliderValue={sliderValue} />
      </motion.div>

      <div className="content">
        <h3 className="title">Rate the game</h3>

        <Face sliderValue={sliderValue} visible={visible} />

        <div
          className="slider"
          onClick={() => {
            if (!visible) setVisible(true);
          }}>
          <div className="slider__desc-wrap">
            <AnimatePresence>
              <motion.p
                className="slider__desc"
                animate={{ opacity: [0, 1], scale: [0, 1] }}
                key={visible ? 'desc1' : 'desc2'}
                exit={{ opacity: 0, scale: 0 }}>
                {visible ? 'Drag the slider and rate the game!' : 'Tap the logo to rate the game!'}
              </motion.p>
            </AnimatePresence>
          </div>

          <CustomSlider
            onSliderChange={(value) => {
              setSliderValue(value);
            }}
            visible={visible}
          />
          <motion.div
            className="slider__marks"
            variants={variants}
            initial="hidden"
            animate={visible ? 'visible' : 'hidden'}>
            {renderMarkLabel(sliderValue)}
          </motion.div>
        </div>
        <motion.div
          variants={variants}
          initial="hidden"
          animate={visible ? 'visible' : 'hidden'}
          className="feedback__wrap">
          <motion.div
            variant="contained"
            variants={feedbackVariants}
            initial="normal"
            animate={SLIDER_STATES[sliderValue]}
            className="feedback__btn">
            Send feedback!
          </motion.div>
        </motion.div>
      </div>
    </>
  );
}
// 626,21 586,26
export default Rating;

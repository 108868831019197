import React from 'react';
import PropTypes from 'prop-types';

const Line = ({ color, bgcolor }) => {
  return (
    <div className="line__wrap">
      <svg
        width="400"
        height="8693.64"
        viewBox="0 -500 400 4693.64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 -500 L350 -500 V1460.852 C350 1480.657 339.979 1499.117 323.371 1509.906 L314.328 1515.781 C280.003 1538.081 283.318 1589.353 320.228 1607.049 C338.423 1615.772 350 1634.16 350 1654.338 V4420 L0 4420 Z"
          fill={bgcolor}
        />

        <path
          d="M350 -500V1460.852C350 1480.657 339.979 1499.117 323.371 1509.906L314.328 1515.781C280.003 1538.081 283.318 1589.353 320.228 1607.049C338.423 1615.772 350 1634.16 350 1654.338V4420"
          stroke="url(#paint0_linear_772_3079)"
          strokeWidth="6"
        />

        <defs>
          <linearGradient
            id="paint0_linear_772_3079"
            x1="6"
            y1="900"
            x2="6"
            y2="3520"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.0839996" stopColor="#1A1A1A" stopOpacity="0" />
            <stop offset="0.149" stopColor="#1A1A1A" />
            <stop offset="0.219" stopColor={color} />
            <stop offset="0.379" stopColor="#1A1A1A" />
            <stop offset="0.699" stopColor="#1A1A1A" />
            <stop offset="0.794" stopColor="#1A1A1A" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

Line.propTypes = {
  color: PropTypes.string,
  bgcolor: PropTypes.string
};

export default Line;
